<template>
  <modal
    name="createProvision"
    height="auto"
    :width="500"
    :scrollable="true"
    @before-open="beforeOpen"
  >
    <div class="add-new-section-dialog">
      <div class="modal-content">
        <div class="add-new-course-page">
          <div class="modal-header">
            <div class="modal-title" id="exampleModalLabel">
              <h5 v-if="customer_id">Edit Section</h5>
              <h5 v-else>Add New Section</h5>
            </div>
            <button type="button" class="close" @click="hide('createProvision')">
              <em class="icon-close"></em>
            </button>
          </div>
          <div class="modal-body">
            <ValidationObserver ref="form">
              <form class="modal-form">
                <div class="form-group">
                  <label class="form-label">Section Name</label>
                  <ValidationProvider name="Name" rules="required" v-slot="{ errors }">
                    <input
                      type="text"
                      class="form-control"
                      id="customerName"
                      aria-describedby="emailHelp"
                      v-model="sectionName"
                      :class="[(isActive && errors[0]) ? 'invalid' : '']"
                    />
                    <span
                      :class="[isActive ? 'invalid' : '']"
                      :style="[isActive ? {'display': 'block'} : {'display': 'none'}]"
                      v-if="errors[0]"
                    >{{ errors[0].replace("The ", "") }}</span>
                  </ValidationProvider>
                </div>
                    <div class="form-group">
                      <label class="form-label" for="exampleInputEmail1">Search Customer</label>
                      <input
                        type="text"
                        class="form-control"
                        placeholder="Search Customer..."
                        v-model="searchCustomers"
                        v-debounce:700ms="getCustomers"
                      />
                      <div v-if="customersList.length">
                        <div class="dropdown-menu" :class="[customersList.length !== 0 ? 'show' : '']">
                          <ul class="dropdown-list">
                            <li
                              class="dropdown-items"
                              v-for="customers in customersList"
                              :key="customers.id"
                            >
                              <div class="dropdown-content">
                                <h4>{{customers.attributes.name}}</h4>
                              </div>
                              <div class="dropdown-action" v-if="!customers.status">
                                <a @click="addCustomers(customers)" class="add-action">+</a>
                              </div>
                              <div class="dropdown-action" v-if="customers.status">
                                <em class="icon-check"></em>
                                <span>Added</span>
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div class="contact-list-section" v-if="addedCustomers.name">
                      <div class="list-container">
                        <div class="list-box">
                          <div class="left-section">
                            <div class="image-box" v-if="addCustomers.photo_url != null">
                                <img alt="images" class="module-img" :src="addCustomers.photo_url" />
                            </div>
                            <h3 class="contact-text">{{addedCustomers.name}}</h3>
                          </div>
                          <div class="right-section">
                          <a class="remove-link" @click="removeCharcter()">Remove</a>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="form-group">
                      <label class="form-label" for="exampleInputEmail1">Search Course</label>
                      <input
                        type="text"
                        class="form-control"
                        placeholder="Search Course.."
                        v-model="searchCourse"
                        v-debounce:700ms="getCourses"
                      />
                      <div v-if="coursesList.length">
                        <div class="dropdown-menu" :class="[coursesList.length !== 0 ? 'show' : '']">
                          <ul class="dropdown-list">
                            <li
                              class="dropdown-items"
                              v-for="customers in coursesList"
                              :key="customers.id"
                            >
                              <div class="dropdown-content">
                                <h4>{{customers.attributes.name}}</h4>
                              </div>
                              <div class="dropdown-action" v-if="!customers.status">
                                <a @click="addCourse({name: customers.attributes.name, id: customers.id })" class="add-action">+</a>
                              </div>
                              <div class="dropdown-action" v-if="customers.status">
                                <em class="icon-check"></em>
                                <span>Added</span>
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div class="contact-list-section" v-if="addedCourses.name">
                      <div class="list-container">
                        <div class="list-box">
                          <div class="left-section">
                            <h3 class="contact-text">{{addedCourses.name}}</h3>
                          </div>
                          <div class="right-section">
                          <a class="remove-link" @click="removeCourse()">Remove</a>
                          </div>
                        </div>
                      </div>
                </div>
                <div class="courses-tab-section">
                  <ul class="tab-section">
                    <li :class="tab === 'address' ? 'tab active' : 'tab'" @click="setTab('address')">
                      <a :class="tab === 'address' ? 'link link-active' : 'link'">Dates</a>
                    </li>
                    <li :class="tab === 'registration' ? 'tab active' : 'tab'" @click="setTab('registration')">
                      <a :class="tab === 'registration' ? 'link link-active' : 'link'">Registration</a>
                    </li>
                    <li :class="tab === 'instructor' ? 'tab active' : 'tab'" @click="setTab('instructor')">
                      <a :class="tab === 'instructor' ? 'link link-active' : 'link'">Instructor(s)</a>
                    </li>
                  </ul>
                  <div class="tab-detail-show" id="address" v-if="tab === 'address'">
                    <div class="add-module-section">
                      <div class="module-input">
                        <div class="row">
                          <div class="col">
                            <div class="form-group">
                              <label class="form-label">Start Date</label>
                              <datepicker v-model="startDate" name="startDate"></datepicker>
                              <div class="calendar-icon">
                                <img alt="images" class="calendar-img" src="../../assets/images/svg/calendar-icon.svg" />
                              </div>
                            </div>
                          </div>
                          <div class="col">
                            <div class="form-group">
                              <label class="form-label">End Date</label>
                              <datepicker v-model="endDate" name="endDate"></datepicker>
                              <div class="calendar-icon">
                                <img alt="images" class="calendar-img" src="../../assets/images/svg/calendar-icon.svg" />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="checkbox-nowrap">
                          <div class="checkbox-section">
                            <div class="form-checkboxes">
                              <label class="custom-checkbox">
                                Flexible end Date
                                <input
                                  type="checkbox"
                                  v-model="flexibleEndDate"
                                  />
                                <span class="checkmark"></span>
                              </label>
                            </div>
                          </div>
                          <div class="checkbox-section">
                            <div class="form-checkboxes">
                              <label class="custom-checkbox">
                                Continuous Start
                                <input
                                  type="checkbox"
                                  v-model="continuousStart"
                                  />
                                <span class="checkmark"></span>
                              </label>
                            </div>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col">
                            <div class="form-group">
                              <label class="form-label">Course Duration</label>
                                <div class="quiz-dropdown course-duration-dropdown">
                                <button
                                    class="btn btn-secondary dropdown-toggle"
                                    type="button"
                                    id="entity"
                                    data-toggle="dropdown"
                                    aria-haspopup="true"
                                    aria-expanded="false"
                                >
                                  {{courseDurationType.name}}
                                    <em class="icon-carrot-down"></em>
                                </button>
                                <div class="dropdown-menu" aria-labelledby="entity">
                                    <option
                                    class="dropdown-item"
                                    @click="selectCourseDuration(type)"
                                    v-for="type of courseDuration"
                                    :key="type.id"
                                    >{{type.name}}</option>
                                </div>
                              </div>
                            </div>
                          </div>
                           <div class="col">
                            <div class="form-group">
                              <label class="form-label">Course Duration Value</label>
                                <input
                                    type="text"
                                    class="form-control"
                                    placeholder="Course Duration Value"
                                    v-model="courseDurationValue"
                                  />
                            </div>
                          </div>
                      </div>
                    </div>
                    </div>
                    <div class="modal-footer">
                      <button type="button" class="btn default" @click="hide">Cancel</button>
                      <button type="button" class="btn primary" @click="setTab('registration')">Next</button>
                    </div>
                  </div>
                </div>
                 <div class="courses-tab-section" v-if="tab === 'registration'">
                  <div class="tab-detail-show" id="registration">
                      <div class="checkbox-nowrap">
                        <div class="checkbox-section">
                          <div class="form-checkboxes">
                            <label class="custom-checkbox">
                              Single registration code
                              <input
                                type="radio"
                                v-bind:value="'single'"
                                v-model="regType"
                                />
                              <span class="checkmark"></span>
                            </label>
                          </div>
                        </div>
                        <div class="checkbox-section">
                          <div class="form-checkboxes">
                            <label class="custom-checkbox">
                              Multiple registration codes
                              <input
                                type="radio"
                                 v-model="regType"
                                 v-bind:value="'multiple'"
                                />
                              <span class="checkmark"></span>
                            </label>
                          </div>
                        </div>
                        <div class="checkbox-section">
                          <div class="form-checkboxes">
                            <label class="custom-checkbox">
                              Instructor provided code
                              <input
                                type="radio"
                                v-bind:value="'instructorprovided'"
                                v-model="regType"
                                />
                              <span class="checkmark"></span>
                            </label>
                          </div>
                        </div>
                      </div>
                      <div class="registration-detail-show single-registration" v-if="regType == 'single'">
                        <div class="form-group">
                          <label class="form-label">Existing</label>
                          <div v-if="codes.length > 0">{{codes[codes.length - 1].attributes.code}}</div>
                        </div>
                        <div class="form-group">
                              <label class="form-label">Edit Registration Code</label>
                              <input
                                  type="text"
                                  class="form-control"
                                  aria-describedby="emailHelp"
                                  v-model="singleRegCode"
                                  placeholder="Enter registration code"
                                />
                        </div>
                        <button v-if="regType == 'multiple' && codes.length == 0" type="button" class="btn secondary" @click="generateCode('single')">Generate</button>
                      </div>
                      <div class="registration-detail-show single-registration" v-if="regType == 'instructorprovided'">
                        <div class="form-group">
                          <label class="form-label">Existing</label>
                          <div>{{instructorProvidedCode}}</div>
                        </div>
                        <div class="form-group">
                              <label class="form-label">Edit Instructor Provided Code</label>
                              <input
                                  type="text"
                                  class="form-control"
                                  aria-describedby="emailHelp"
                                  v-model="instructorProvidedCode"
                                  placeholder="Enter registration code"
                                />
                        </div>
                        <button v-if="regType == 'multiple' && codes.length == 0" type="button" class="btn secondary" @click="generateCode('single')">Generate</button>
                      </div>
                      <div class="registration-detail-show" v-if="regType == 'multiple'">
                          <div class="code-generae-section">
                            <div class="form-group">
                              <label class="form-label">Number of codes to generate</label>
                                <input
                                  type="text"
                                  class="form-control"
                                  aria-describedby="emailHelp"
                                  v-model="numOfCode"
                                  placeholder="Enter number of codes"
                                />
                            </div>
                          </div>
                          <div class="code-generae-section">
                            <div class="form-group">
                              <label class="form-label">Code prefix</label>
                              <input
                                  type="text"
                                  class="form-control"
                                  aria-describedby="emailHelp"
                                  v-model="codePrefix"
                                  placeholder="Enter code"
                                />
                            </div>
                            <button type="button" class="btn secondary" @click="generateCode()">Generate</button>
                          </div>
                          <div class="used-code-section">
                            <div class="detail-left">
                              <h3 class="title">List of single-use codes</h3>
                              <div class="code-detail">
                                <div class="code-text"><strong>{{totalCodes}} </strong> Codes</div>
                                <div class="divider"></div>
                                 <div class="code-text"><strong>{{usedCodes}} </strong>  Used</div>
                              </div>
                            </div>
                            <div class="detail-right">
                              <a href="javascript:void(0);"  class="btn secondary" @click="exportCsv()">Export CSV</a>
                            </div>
                          </div>
                          <div class="code-used-container">
                            <div class="code-used-box">
                              <div class="title-detail">
                                <div class="title-left">
                                Codes
                                </div>
                                <div class="title-right">
                                  Used
                                </div>
                              </div>
                              <v-infinite-scroll @bottom="nextPage" class="manage-scroller">
                                  <div class="code-nowrap" v-for="code in codes" :key="code.id">
                                    <div class="text-code-section">
                                      {{code.attributes.code}}
                                    </div>
                                    <div class="text-used-section">
                                      {{code.attributes.used == false ? 'No' : 'Yes'}}
                                    </div>
                                  </div>
                              </v-infinite-scroll>
                            </div>
                          </div>
                      </div>
                    </div>
                    <div class="modal-footer">
                      <button type="button" class="btn default" @click="hide">Cancel</button>
                      <button type="button" class="btn primary" @click="setTab('instructor')">Next</button>
                    </div>
                  </div>
                <div class="courses-tab-section" v-if="tab === 'instructor'">
                  <div class="tab-detail-show" id="instructor">
                   <div class="form-group">
                      <label class="form-label" for="exampleInputEmail1">Instructor</label>
                      <input
                        type="text"
                        class="form-control"
                        placeholder="Search Instructor..."
                        v-model="searchInstructor"
                        v-debounce:700ms="getInstructor"
                      />
                      <div v-if="instructorsList.length">
                        <div class="dropdown-menu" :class="[instructorsList.length !== 0 ? 'show' : '']">
                          <ul class="dropdown-list">
                            <li
                              class="dropdown-items"
                              v-for="instructor in instructorsList"
                              :key="instructor.id"
                            >
                              <div class="dropdown-content">
                                <h4>{{instructor.attributes.first_name}} {{instructor.attributes.last_name}}</h4>
                                 <h4>{{instructor.attributes.email}}</h4>
                              </div>
                              <div class="dropdown-action" v-if="!instructor.status">
                                <a @click="addInstructors(instructor)" class="add-action">+</a>
                              </div>
                              <div class="dropdown-action" v-if="instructor.status">
                                <em class="icon-check"></em>
                                <span>Added</span>
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div class="contact-list-section">
                      <div class="list-container">
                        <h2 class="title-text" v-if="addedInstructors.length > 0">Instructors ({{addedInstructors.length}})</h2>
                        <div class="list-box" v-for="(instr,i) of addedInstructors" :key="instr.id">
                          <div class="left-section">
                            <h3 class="contact-text">{{instr.firstName}} {{instr.lastName}}</h3>
                            <p class="email">{{instr.email}}</p>
                          </div>
                          <div class="right-section">
                          <a class="remove-link" @click="deleteInstructor(i)">Remove</a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="modal-footer">
                    <button type="button" class="btn default" @click="hide">Cancel</button>
                    <button type="button" class="btn primary" @click="createSection('')">Save</button>
                  </div>
                </div>
              </form>
            </ValidationObserver>
          </div>
        </div>
      </div>
    </div>
  </modal>
</template>
<script>
import api from "../../services/api";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { utilFunctionService } from "@/utils/utils.service";
import { commonConfig } from "@/utils/commonConfig";
import Datepicker from 'vuejs-datepicker';

export default {
  name: "createProvision",
  components: {
    ValidationProvider,
    ValidationObserver,
    Datepicker
  },
  data() {
    return {
      settings: {
        maxScrollbarLength: false
      },
      name: "",
      description: "",
      searchCustomers: "",
      customersList: [],
      addedCustomers: [],
      removeCharacters: [],
      searchRole: "",
      customers: [],
      roles: [],
      isActive: false,
      url: utilFunctionService.getImageUrl(),
      customer_id: "",
      showRole: "",
      customerType: "",
      customerName: "",
      customerTypes: [],
      note: '',
      tab: 'address',
      email: "",
      contactFirstName: "",
      contactLastName: "",
      contactEmail: "",
      contactPhone: "",
      selectedRole: {'attributes': {
        'name': ''
      }},
      contactList: [],
      removeContacts : [],
      searchModule: "",
      modulesList: [],
      addedModules: [],
      invoiceFrequency: [
                          {name: 'Daily', id: 'daily'},
                          {name: 'Weekly', id: 'weekly'}
                        ],
      courseDuration: [
                        {name: 'Days', id: 'days'},
                        {name: 'Weeks', id: 'weeks'}
      ],
      startDate: new Date(),
      endDate: new Date(),
      flexibleEndDate: false,
      continuousStart: false,
      courseDurationValue: '',
      searchCourse: '',
      coursesList: [],
      invoiceType: {name: 'Weekly', id: 'weekly'},
      courseDurationType: {name: 'Weeks', id: 'weeks'},
      addedCourses: {},
      sectionName: '',
      regType: 'single',
      searchInstructor: '',
      instructorsList: [],
      addedInstructors: [],
      codes : [],
      usedCodes: 0,
      codePrefix: "",
      singleRegCode: "",
      instructorProvidedCode: "",
      page: 1,
      totalPages: 1,
      totalCodes: 0
    };
  },
  methods: {
     nextPage() {
      ++this.page;
      if (this.page <= this.totalPages) {
        this.getRegistrationCode();
      } else {
        return;
      }
    },
    removeCourse() {
      this.addedCourses = {}
    },
    exportCsv() {
      api
          .exportRegCodeCsv(this.customer_id, 1)
          .then(response => {
            let res = response.data.csv_data;
            let fileName = response.data.filename;
            const url = window.URL.createObjectURL(new Blob([res], {type: 'text/csv'}))
            var link = document.createElement('a')
            link.href = url
            link.setAttribute('download', fileName);
            document.body.appendChild(link);
            link.click();
            utilFunctionService.hideLoader();
          })
          .catch();
    },
    addCourse(e) {
      this.addedCourses = e;
      this.coursesList = [];
      this.searchCourse = '';
    },
    removeModule(index) {
      this.addedModules.splice(index, 1);
    },
    selectInvoiceFrequency(type) {
      this.invoiceType = type;
    },
    selectCourseDuration(type) {
      this.courseDurationType = type;
    },
    deleteInstructor(i) {
      if (this.addedInstructors[i].id) {
        this.removeInstructors.push(this.addedInstructors[i])
      }
      this.addedInstructors.splice(i,1);
    },
    setTab(currentTab) {
      this.tab = currentTab;
      if (currentTab == 'registration') {
        if (!this.customer_id && this.addedCustomers) {
          this.createSection('registration');
        } else {
          this.getRegistrationCode();
        }
      }
    },
    selectedCustomerType(customer) {
      this.customerType = customer
    },
    selectContactRole(contactRole) {
      this.selectedRole = contactRole
    },
    getCustomerType() {
        api
          .getCustomerTypes()
          .then(res => {
            this.customerTypes = res.data;
          })
          .catch();
    },
    addInstructors(e) {
      this.instructorsList = [];
      this.addedInstructors.push({'firstName': e.attributes.first_name, 'lastName': e.attributes.last_name, 'email': e.attributes.email, 'user_id': e.id});
      this.searchInstructor = "";
    },
    getInstructor() {
      const page = 1;
      if (this.searchInstructor.length === 0) {
        this.instructorsList = [];
      } else {
        api
          .getAllInstructors(page, this.searchInstructor)
          .then(res => {
            this.instructorsList = res.data.data;
            if (this.addedInstructors.length > 0) {
              this.addedInstructors.forEach(element => {
                const index = this.instructorsList.findIndex(
                  (elem) => {
                   return parseInt(elem.id) === parseInt(element.id)
                  }
                );
                if (index !== -1) {
                  this.instructorsList[index].status = true;
                }
              });
            }
          })
          .catch();
      }
    },
    getCustomers() {
      const page = 1;
      if (this.searchCustomers.length === 0) {
        this.customersList = [];
      } else {
        api
          .searchCustomers(page, this.searchCustomers)
          .then(res => {
            this.customersList = res.data.data;
            if (this.addedCustomers.length > 0) {
              this.addedCustomers.forEach(element => {
                const index = this.customersList.findIndex(
                  (elem) => {
                   return parseInt(elem.id) === parseInt(element.id)
                  }
                );
                if (index !== -1) {
                  this.customersList[index].status = true;
                }
              });
            }
          })
          .catch();
      }
    },
    getCourses() {
      if (this.searchCourse.length === 0) {
        this.coursesList = [];
      } else {
        api
          .searchCustomerCourse(this.addedCustomers.customer_id)
          .then(res => {
            this.coursesList = res.data.data;
            if (this.addedCousre.length > 0) {
              this.addedCousre.forEach(element => {
                const index = this.coursesList.findIndex(
                  (elem) => {
                   return parseInt(elem.id) === parseInt(element.id)
                  }
                );
                if (index !== -1) {
                  this.coursesList[index].status = true;
                }
              });
            }
          })
          .catch();
      }
    },
    createSection(tab) {
      this.$refs.form.validate().then(success => {
        this.isActive = true;
        if (!success) {
          return;
        } else {
          const fb = new FormData();
          fb.append("section[name]", this.sectionName);
          fb.append(
            "section[customer_course_id]", this.addedCourses.id
          );
          fb.append(
            "section[start_date]", this.startDate
          );
          fb.append(
            "section[end_date]", this.endDate
          );
          fb.append(
            "section[flexible_end_date]", this.flexibleEndDate
          );
          fb.append(
            "section[continuous_start]", this.continuousStart
          );
          fb.append(
            "section[course_duration_value]", this.courseDurationValue
          );
          fb.append(
            "section[course_duration_type]", this.invoiceType.id
          );
          if (this.regType == 'single' && this.singleRegCode) {
            fb.append(
              "section[single_reg_code]", true
            );
            fb.append(
              "section[lti_no_reg_code]", false
            );
            fb.append(
              "section[single_reg_code_prefix]", this.singleRegCode
            );
          } else if (this.regType == 'multiple' && this.codePrefix) {
            fb.append(
              "section[single_reg_code]", false
            );
            fb.append(
              "section[lti_no_reg_code]", false
            );
            fb.append(
              "section[multi_reg_code_prefix]", this.codePrefix
            );
          } else if (this.regType == 'instructorprovided' && this.instructorProvidedCode) {
            fb.append(
              "section[single_reg_code]", false
            );
            fb.append(
              "section[lti_no_reg_code]", true
            );
            fb.append(
              "section[multi_reg_code_prefix]", ''
            );
            fb.append(
              "section[single_reg_code_prefix]", ''
            );
            fb.append(
              "section[lti_course_id]", this.instructorProvidedCode
            );
          } else {
            fb.append(
              "section[single_reg_code]", false
            );
            fb.append(
              "section[multi_reg_code_prefix]", ''
            );
          }
          if (this.addedInstructors.length > 0) {
            this.addedInstructors.forEach((char) => {
              if (!char.id) {
               fb.append('section[section_instructors_attributes][][user_id]', char.user_id)
              }
            })
          }

        if (this.removeInstructors.length > 0) {
            this.removeInstructors.forEach((inst) => {
              if (inst.id)
              fb.append('section[section_instructors_attributes][][id]', inst.id)
              fb.append('section[section_instructors_attributes][][_destroy]', true)
            })
          }

          if (this.customer_id) {
            fb.append("id", this.customer_id);
            api.editProvision(this.customer_id, fb).then(() => {
              utilFunctionService.showSuccess(commonConfig.appMessages.sectionEdit);
              this.hide("createProvision");
            });
          } else {
            api.createProvision(fb).then((res) => {
              this.customer_id = res.data.data.id
              if(tab != 'registration') {
               utilFunctionService.showSuccess(
                commonConfig.appMessages.sectionCreate
              );
               this.hide("createProvision");
              }
            });
          }
        }
      });
    },
    clearForm() {
      this.name = "";
      this.description = "";
      this.customers = [];
      this.addressPhone = "",
      this.province = "",
      this.sectionName = "",
      this.tab = 'address',
      this.email = "",
      this.contactList = [];
      this.removeContacts = [];
      this.removeInstructors = [];
      this.addedCustomers = {};
      this.numOfCode = '';
      this.singleCode = "";
      this.codes = [];
      this.addedInstructors = [];
      this.regType = 'single';
      this.startDate = new Date();
      this.endDate = new Date();
      this.flexibleEndDate = false;
      this.continuousStart = false;
      this.courseDurationValue = '';
      this.searchCourse = '';
      this.coursesList = [];
      this.invoiceType = {name: 'Weekly', id: 'weekly'};
      this.addedCourses = {};
      this.codePrefix = '';
      this.totalCodes = 0;
      this.page = 1;
      this.totalPages = "";
    },
    hide() {
      this.clearForm();
      this.$parent.hide("createProvision");
    },
    addCustomers(e) {
      this.customersList = [];
      this.addedCustomers = {'name': e.attributes.name, 'customer_id': e.id, 'code': e.attributes.uniq_code};
      this.searchCustomers = "";
      this.codePrefix = this.addedCustomers.code;
    },
    removeCharcter() {
      this.addedCustomers = {};
    },
    beforeOpen(event) {
      this.clearForm();
      this.getCustomerType();
      this.customer_id = event.params.customer_id;
      this.tab = event.params.tab;
      if (this.customer_id)
      this.getSectionData();
    },
    generateCode() {
      const trimmedCodePrefix = this.codePrefix.replace(/\s+/g, '');
      this.codePrefix = trimmedCodePrefix;
       api
        .generateCode(this.customer_id, this.regType == 'single' ? 1 : this.numOfCode, trimmedCodePrefix)
        .then(() => {
          this.getRegistrationCode();
        })
    },
    getRegistrationCode() {
        api
        .generateRegCode(this.customer_id, this.page)
        .then((res) => {
            this.codes = [...this.codes, ...res.data.data]
            if (this.codes.length == 1) {
              this.singleRegCode = this.codes[0].attributes.code;
            }
            const filterUsedCodes = this.codes.filter(data => data.attributes.used == true);
            this.usedCodes = filterUsedCodes.length;
            this.totalPages = res.data.total_pages;
            this.totalCodes = res.data.filtered_count;
        })
    },
    getSectionData() {
      api
        .getSectionData(this.customer_id)
        .then(res => {
          this.sectionName = res.data.data.attributes.name;
          this.addedCustomers = {'name': res.data.data.attributes.customer_name};
          this.addedCourses = {name: res.data.data.attributes.course_name, id : res.data.data.attributes.customer_course_id};
          this.startDate = res.data.data.attributes.start_date;
          this.endDate = res.data.data.attributes.end_date;
          this.continuousStart = res.data.data.attributes.continuous_start;
          this.flexibleEndDate = res.data.data.attributes.flexible_end_date;
          this.courseDurationValue = res.data.data.attributes.course_duration_value;
          this.instructorProvidedCode = res.data.data.attributes.lti_course_id;
          if(res.data.data.attributes.lti_no_reg_code) {
            this.regType = 'instructorprovided'
          } else {
            this.regType = res.data.data.attributes.single_reg_code ? 'single' : 'multiple';
          }
          
          const instructors = res.data.data.attributes.section_instructors;

          if (instructors.length > 0) {
            instructors.map((instructor) => {
                this.addedInstructors.push({'firstName': instructor.attributes.user.attributes.first_name ,'lastName': instructor.attributes.user.attributes.last_name, 'email': instructor.attributes.user.attributes.email , 'id': instructor.id, 'user_id' : instructor.attributes.user_id})
            })
          }

          this.invoiceType = res.data.data.attributes.course_duration_type == 'weekly' ? {name: 'Weeks', id: 'weekly'} :  {name: 'Days', id: 'daily'};
          if (res.data.data.attributes.single_reg_code) {
            this.codePrefix = res.data.data.attributes.single_reg_code_prefix
          } else {
            this.codePrefix = res.data.data.attributes.multi_reg_code_prefix
          }
        })
        .catch();
    }
  }
};
</script>
